@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,700;1,100;1,400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
.app {
  background-color: #050505;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  color: white;

}

.ellipse {
  width: 250px;
  height: 250px;
  border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
  background-color: #727272;
  position: relative;
  display: flex;
  overflow-y: visible;
  overflow-x: hidden;
}

.glass_container {
  background: linear-gradient(to right top, #050505, #060606);
  border-radius: 16px;
  padding: 0;
}

.glass {
  background: rgba(255, 255, 2555, 0.02);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #3d685f;
}

.glass_2 {
  background: rgba(255, 255, 2555, 0.02);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #3d685f;
}

@media screen and (min-width: 768px) {
  .ellipse {
    width: 350px;
    height: 350px;
  }
}

@media screen and (min-width: 1024px) {
  .ellipse {
    width: 500px;
    height: 480px;
  }
}
